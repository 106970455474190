import {
  AccordionGroup,
  Box,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import { useMediaQuery } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import SapphireSaturdaysWeek, {
  WeekData,
} from "./SapphireSaturdays/SapphireSaturdaysWeek";
import Footer from "./components/Footer";
import ModeToggle from "./components/ModeToggle";

const sapphireShoreWeek1Data: WeekData = {
  question: {
    weekTitle: "Week 1",
    paragraphs: [
      "You find yourself at the fringes of The Foreboding Forest, at the heart of Osgara. You have no real recollection of how you got here. Is this a side effect of that subscribe button you once pressed? Or have the whims of the 'algorithm' led you here? All you really know is that this adventure has rails, but at least the path before you seems to branch.",
      "One of the two great capitals awaits you, Orvabad, to the West, and a visit to the empire that claims to sit at the Pinnacle of Civilisation. Or perhaps you fancy the road east, to Tunhil. To see the various Migoran provinces, and from there perhaps even find your way to the sea.",
      "Of course, you -could- choose to remain in place and ponder more existential questions. But where's the fun in that? After all, an adventure awaits!",
    ],
    choices: [
      { id: "hydaria", value: "WEST: To Hydaria" },
      { id: "migora", value: "EAST: To Migora" },
      { id: "stay", value: "STAY: Who even am I?" },
      { id: "south", value: "South was never an option 💀" },
    ],
    selectedChoiceId: "migora",
  },
  answer: {
    answerTitle: "And east to Migora you go!",
    videoAnswers: [
      {
        paragraphs: [
          "Combating the allure of travel to more sophisticated realms, you make your way east, to the Kingdom of Migora. With only an unclear pull towards the capital you go, and quickly you find a merchant who gladly takes you with him on his cart.",
          "From brief small talk, he realises you are unfamiliar with Migora's past, and so he starts to share of what he knows about the current Kingdom, and what was once the Ralmera of Migora.",
        ],
        link: "https://youtube.com/shorts/O17cAv-H_so",
      },
      {
        paragraphs: [
          "Your journey continues through Migora, you notice the Merchant begins to take a more creative route. Leaving paved roads for ones less travelled by, he tells you that he wishes to avoid passing through the city of Mithra.",
          "When pressed for an explanation, he says that he is a merchant, and that this province's only trade is of war. When asked about this province, he offers a more detailed explanation.",
        ],
        link: "https://youtube.com/shorts/Q7JBxmgqCL0",
      },
    ],
  },
};

const sapphireShoreWeek2Data: WeekData = {
  question: {
    weekTitle: "Week 2",
    paragraphs: [
      "The road winds far ahead still; you have just passed the bastion city of Mithra and find yourself back on paved roads. Although, not too far from the road, you spot a makeshift archery range along with an entire troop of men and women practicing.",
      "The merchant that has been ferrying you along seems intent on avoiding them, but you see them gather around a shorter brown haired woman who seems to be lecturing them.",
      "You know you have considerable ground to cover still, but you also know that opportunities like this may be rare, though risky.",
    ],
    choices: [
      { id: "stop", value: "Stop the cart and approach quietly" },
      { id: "go", value: "Favour the road and conversation" },
    ],
    selectedChoiceId: "stop",
  },
  answer: {
    answerTitle: "Quietly, you begin to approach...",
    videoAnswers: [
      {
        paragraphs: [
          "You stop the cart and hop off, intent on approaching cautiously, but your companion calls out to you. He urges you to get back on the cart and make haste before you are spotted.",
          "His demeanour makes you inquire about what has him this rattled, and so he explains who you see before you in the distance.",
        ],
        link: "https://youtube.com/shorts/P61zfN5WZXc",
      },
      {
        paragraphs: [
          "With that warning in place, you decide to still press on and see what it is this woman is explaining to the gathered troop of younger faces. You lay low, pace your breathing, and find a path through and around a thicket to get you within hearing range.",
          "Lady Eleia has been lecturing them for a while it seems, but the topic you arrived at seemed to be more historic in nature.",
        ],
        link: "https://youtube.com/shorts/PiKF87mvyiQ",
      },
      {
        paragraphs: [
          "You remain in hiding, intrigued perhaps by the history of these Migorans, or at least the narrow and subjective view one of their own offered as she lectured on.",
          "A question was asked about the Sapphire Guard, and if they were a product of High King Alon's new military restructuring, as well as questions about Mithra's City as a whole, and Eleia would gladly answer.",
        ],
        link: "https://youtube.com/shorts/uT-Vhy8iqxY",
      },
      {
        paragraphs: [
          "Eleia continues with her explanation of how Mithra's City was formed, and you only need look over your shoulder to see it in the distance.",
          "Paired with Eleia's narration it becomes clear why the city looks the way it does, as strange as it may seem when contrasted to most other cities in Osgara.",
        ],
        link: "https://youtube.com/shorts/ISFssjKpMAo",
      },
    ],
  },
};

const sapphireShoreWeek3Data: WeekData = {
  question: {
    weekTitle: "Week 3",
    paragraphs: [
      "As Eleia winds down she begins to send her troops back to training, and as she does so her ears pick up a rustling from your direction. Did she hear you? Was it something you startled that caught her attention?",
      "Either way, she has drawn her bow, and is approaching slowly.",
      "The terrain between here and the cart is dense enough to make any archer struggle, even Eleia. She seems on high alert, you could always converse but explanations might be difficult. Then again, you've snuck here once, could you do it again with her full attention upon you?",
    ],
    choices: [
      { id: "run", value: "RUN: It's not too far to the cart." },
      { id: "talk", value: "TALK: What's the worst that could-" },
      { id: "sneak", value: "SNEAK: Perhaps it works again?" },
    ],
    selectedChoiceId: "run",
  },
  answer: {
    answerTitle: "Quickly, you try to make your escape...",
    videoAnswers: [
      {
        paragraphs: [
          "Through the thicket you quickly make an escape, the last stretch to the cart is a short one, but you hear a bowstring sing. One quick motion from you saves you from a quick demise, but a second shot finds your leg.",
          "A sudden surge of pain streams through your body as you tumble over, but the merchant you accompanied catches you before you meet the ground. Quickly he helps you to the cart and you both make your escape.",
          "Though with how slow you are going, you have to wonder what kind of horses are pulling your cart, and your companion offers an answer.",
        ],
        link: "https://youtube.com/shorts/kY9-beVOq1A",
      },
      {
        paragraphs: [
          "The cart rattles along as you nurse your leg back to health, at least the roads are mercifully well made. Your companion catches you staring at them, and offers up an explanation.",
        ],
        link: "https://youtube.com/shorts/BA1tRDAfORU",
      },
    ],
  },
};

const sapphireShoreWeek4Data: WeekData = {
  question: {
    weekTitle: "Week 4",
    paragraphs: [
      "The road to Tunhil remains long still, and with your leg still healing there is little to be done than accept a more eventless trip to the Migoran Capital. Though after a quick stop in Tunira, you find yourself joined with two more people.",
      "A sturdy looking fellow, hailing from Motra by the looks of it, and a woman who keeps to herself and keeps the hood of her cloak up at all times. The merchant implores you to keep to yourself as well, but knowing that you do as you please he at least asks you for discretion.",
      "What you do next, however, is up to you. The road is long still, and the most you can do now is converse. For a change, it seems you have options.",
    ],
    choices: [
      { id: "merchant", value: "MERCHANT: More about Migora." },
      { id: "motran", value: "MOTRAN: What tidings from the south?" },
      { id: "woman", value: "WOMAN: What is she hiding?" },
      { id: "silence", value: "SILENCE: Keep completely to yourself." },
    ],
    selectedChoiceId: "woman",
  },
  answer: {
    answerTitle: "Cautiously... you strike up conversation,",
    videoAnswers: [
      {
        paragraphs: [
          "At first she seems unwilling to speak much, but then notices that you are not like the rest of these Migorans, far from it. And so she allows herself to strike up some conversation, though you quickly find that she is not one to give simple answers.",
          "She takes to calling you 'Wanderer', since you are clearly not Migoran but she cannot pin where you might hail from. And instead of giving you a name when asked, she instead tells you about how names are given throughout the five great realms of Osgara.",
        ],
        link: "https://youtube.com/shorts/ca1WRBzDHbo",
      },
      {
        paragraphs: [
          "As you continue conversing and as you point out subtly that she is clearly not from Migora either. She opens up at least about one thing but only after asking for your discretion. She claims to be a merchant from Hydaria, and she goes on to explain why the need for secrecy, and what brings here to Migora of all places.",
        ],
        link: "https://youtube.com/shorts/u_R6ulh0yZA",
      },
      {
        paragraphs: [
          "A few days go by and your road takes you near farmsteads that capture this woman's attention. You ask if she's been here before but she shakes her head. After a quick glance your direction she tells you that she is admiring the site of a lesser known battle in the second war of flames, the battle of Arleth Stead.",
        ],
        link: "https://youtube.com/shorts/KYGpPSlcam8",
      },
    ],
  },
};

const sapphireShoreWeek5Data: WeekData = {
  question: {
    weekTitle: "Week 5",
    paragraphs: [
      "Your leg has been healing well as the journey continues, and finally you reach a crossroads. A small fortification guards this crossroads, which serves also as a lax checkpoint for merchants going in and out of the Heartlands of Migora.",
      "The last *leg* of your journey awaits, but only after a routine inspection. The golden haired Hydarian woman seems slightly anxious, perhaps slightly more so than what ought to be expected from a smuggler. The merchant meanwhile seems to be ready to leave the cart and go clarify his cargo with these provincial guards. The Motran seems to be gathering what little belongings he had, and comes to bid his farewells, readying himself for a trek north.",
    ],
    choices: [
      { id: "merchant", value: "Help the Merchant" },
      { id: "woman", value: "Continue conversing with the Woman." },
      { id: "wander", value: "Wander around the checkpoint." },
      { id: "motran", value: "Follow the Motran." },
    ],
    selectedChoiceId: "woman",
  },
  answer: {
    answerTitle:
      "Midst the oncoming inspection, you remain with the woman who starts revealing more of her secrets, and of her noble heritage...",
    videoAnswers: [
      {
        paragraphs: [
          "You remain near the cart with the slightly distressed woman, for which she thanks you deeply. While it is understandable that she be worried about Migoran inspection, you feel as though there is more to this story.",
          "And finally, after weeks of companionship, it seemed she trusted you enough to let you in on a secret. And curiously enough, it was related to her name, and the name she would give these Migoran inspectors.",
          "She began by saying that her last name was 'of Hida's Retainers' and went on to explain why this was relevant.",
        ],
        link: "https://youtube.com/shorts/8jv-qMRBc-4",
      },
      {
        paragraphs: [
          "With one part of her explanation out of the way, she continues onto why Migora is different. And the name she would have to give these inspectors should they choose to pursue her case.",
        ],
        link: "https://youtube.com/shorts/IM1bTmRKoCI",
      },
      {
        paragraphs: [
          "You both hear a rattling come from near the cart. An inspection? Thieves? The only way is to turn to look, and only then do you two realise that the merchant has returned, and he immediately beacons you both to join him.",
          "Everything went well, for a change. He says that Osgaag's are quieter days for this checkpoint, but the merchant explains that his cargo is expected by Summer Third. And when these names puzzle you, the Hydarian Noblewoman starts to explain.",
        ],
        link: "https://youtube.com/shorts/YnibMnBtAZc",
      },
      {
        paragraphs: [
          "With all of you back on the road, and the Motran parted bound for an errand north. You are left with the merchant and Arlienne of Hida's Retainers. But your extended conversations with Arlienne might have soured the merchant slightly, not out of disdain, rather out of worry for her task.",
          "As you've taken more of an interest to her, and as prideful as she was of her Hydarian heritage, she began expanding on life in Hydaria as you made your way to Tunhil.",
        ],
        link: "https://youtube.com/shorts/uXzbTDq6qoM",
      },
      {
        paragraphs: [
          "The journey sped on, and Tunhil began to loom in the far distance. And yet, as soon as you laid eyes on it, your eyes closed as you descend into an unsettled dream.",
        ],
        link: "https://youtube.com/shorts/Rm_6Y-0wI7o",
      },
      {
        paragraphs: [
          "You wake up, and finally, you find yourself arriving at your destination.",
        ],
        link: "https://youtube.com/shorts/5wdHpfO7FFg",
      },
    ],
  },
};

const sapphireShoreWeek6Data: WeekData = {
  question: {
    weekTitle: "Week 6",
    paragraphs: [
      "You arrive in Tunhil! And from the merchant’s cart, you are treated to a view of more people than you’ve seen so far in Migora. Your approach had been through The Gate of Traders, which starkly contrasted the wealthy exterior of the city once one set foot within.",
      "Here, the walls were high enough to block out the sun for massive stretches of land, and this gate led through some of the more industrious districts of Tunhil. But more important than the city, was a connection you felt which you had not felt since you set out from the fringes of the Foreboding forest.",
      "The cart moved, but you felt the urge to move in a different direction. As if you were drawn to meet someone you had never heard of. But perhaps these premonitions had a different explanation as well. Should you remain with your current company, perhaps the opportunity would present itself to understand this mental tug.",
      "What was certain was the undeniable size of the city that almost beggars belief. What choice came next was shrouded in uncertainty. To stay with this group you’ve come to know? Or to wander on your own and forge your own path through the unknown?",
      "You take solace in the fact that, at the very least, your leg had healed.",
    ],
    choices: [
      { id: "stay", value: "Stay for now." },
      { id: "wander", value: "Wander the sprawling city." },
    ],
    selectedChoiceId: "wander",
  },
  answer: {
    answerTitle: "And so you wander the streets of Tunhil...",
    videoAnswers: [
      {
        paragraphs: [
          "You take to wandering the streets, telling your companions you have an errand to run. Of all the different scenes available to you, your feet call you towards an alley.",
          "And there you stand before a beggar. With two books in front of his feet.",
        ],
        link: "https://www.youtube.com/shorts/f5txjcIDsNE?feature=share",
      },
    ],
  },
};

const sapphireShoreWeek7Data: WeekData = {
  question: {
    weekTitle: "Week 7",
    paragraphs: [
      "Two books lie before your feet, which one do you reach for? Or do you attempt to resist the pull entirely?",
    ],
    choices: [
      { id: "blood", value: "The Flight of Blood." },
      { id: "sands", value: "The Veil of Sands" },
      { id: "neither", value: "Resist the pull." },
    ],
    selectedChoiceId: "neither",
  },
  answer: {
    answerTitle:
      "With great fortitude, you resist the pull of the uncertain, and reunite with your companions...",
    videoAnswers: [
      {
        paragraphs: [
          "Leaving the crazed beggar laughing his insanity away, you run back out of the alley, and retrace your steps back the way you came.",
          "Until finally, a familiar voice calls out to you. Your companions were getting ready to enter a tavern, and gladly you go back to them for now.",
        ],
        link: "https://youtube.com/shorts/zvpH_vqs9J4?feature=share",
      },
      {
        paragraphs: [
          "You all go inside, find a table, and the merchant starts telling you about the place you are in as he awaits a business associate.",
          "Meanwhile, as he talks, Arlienne excuses herself for a moment, leaving you to the merchant's long winded conversation, with thoughts of what you'd seen earlier swimming in your mind.",
        ],
        link: "https://youtube.com/shorts/37zF-HnkomI?feature=share",
      },
    ],
  },
};

const sapphireShoreWeek8Data: WeekData = {
  question: {
    weekTitle: "Week 8 (Live)",
    paragraphs: [
      "The merchant spots his business partner entering the tavern, and is about to excuse himself for the moment, and Arlienne has yet to return, what do you do?",
    ],
    choices: [
      { id: "tell", value: "'There is something you should know...'" },
      { id: "arl", value: "Search for Arlienne." },
      { id: "food", value: "Order an Arveyan Special." },
    ],
    selectedChoiceId: "",
  },
  // answer: {
  //   answerTitle: "Cautiously... you strike up conversation,",
  //   videoAnswers: [
  //     {
  //       paragraphs: [
  //         "At first she seems unwilling to speak much, but then notices that you are not like the rest of these Migorans, far from it. And so she allows herself to strike up some conversation, though you quickly find that she is not one to give simple answers.",
  //         "She takes to calling you 'Wanderer', since you are clearly not Migoran but she cannot pin where you might hail from. And instead of giving you a name when asked, she instead tells you about how names are given throughout the five great realms of Osgara.",
  //       ],
  //       link: "https://youtube.com/shorts/ca1WRBzDHbo",
  //     },
  //   ],
  // },
};

export default function SapphireSaturdays() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={theme}>
      <Sheet
        variant="plain"
        color="neutral"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <ModeToggle />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography variant="plain" level={!md ? "h1" : "h3"}>
              SAPPHIRE SATURDAYS
            </Typography>
            <Typography
              variant="plain"
              level="body-sm"
              textTransform={"uppercase"}
            >
              The Story so far...
            </Typography>

            <AccordionGroup>
              <SapphireSaturdaysWeek week={sapphireShoreWeek1Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek2Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek3Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek4Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek5Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek6Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek7Data} />
              <SapphireSaturdaysWeek week={sapphireShoreWeek8Data} />
            </AccordionGroup>
          </Box>

          <CookieConsent
            enableDeclineButton
            acceptOnScrollPercentage={5}
            onAccept={(acceptedByScrolling) => {
              if (acceptedByScrolling) {
                // Accepted by scrolling
              } else {
                // Accepted by clicking
              }
            }}
          >
            <Typography>
              This website uses cookies to enhance the user experience.
            </Typography>
          </CookieConsent>
        </Box>
      </Sheet>
      <Footer />
    </ThemeProvider>
  );
}
