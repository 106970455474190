import { Box, Card, Chip, Stack, Tooltip, Typography } from "@mui/joy";
import { useColorScheme } from "@mui/joy/styles";
import { useMediaQuery, useTheme } from "@mui/material";

import ReactPlayer from "react-player";
import LysiacElement from "./../assets/LysiacElement.webp";
import MithraElement from "./../assets/MithraElement.webp";
import SapphireShoreArtPiece from "./../assets/SapphireShoreArtPiece.webp";
import TheSecondOneArtPiece from "./../assets/TheSecondOneArtPiece.webp";
import TheThirdOneArtPiece from "./../assets/TheThirdOneArtPiece.webp";
import ButtonRedirects from "./ButtonRedirects";

export default function StainedHeader() {
  const { mode } = useColorScheme();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography variant="plain" level={!md ? "h1" : "h3"}>
        TIMELESS' ARIA
      </Typography>
      <ReactPlayer
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          marginBottom: "16px",
        }}
        width={"100%"}
        url={"https://youtu.be/Zti-1PIOts8"}
        controls
        muted
        loop
        playing
      ></ReactPlayer>
      <Typography variant="plain" level="body-sm" textTransform={"uppercase"}>
        A High Fantasy Book Series
      </Typography>
      <Typography variant="plain" level="body-sm" textTransform={"uppercase"}>
        By Mostafa A. M. Elbehery & Aly M. A. Said
      </Typography>

      <Stack
        direction={!md ? "row" : "column"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          m: 2,
        }}
      >
        <Card variant="soft" sx={{ alignItems: "center", m: 2, width: "40%" }}>
          <img
            loading="lazy"
            src={SapphireShoreArtPiece}
            alt="SapphireShoreArtPiece By: Rana Fetit"
            width={!md ? "70%" : "40%"}
          />
          <Typography variant="plain" level={!md ? "title-md" : "title-sm"}>
            BOOK 1
          </Typography>
          <Typography
            variant="plain"
            level={!md ? "title-lg" : "title-md"}
            textAlign={"center"}
          >
            THE SAPPHIRE SHORE
          </Typography>
          <Typography variant="plain" level="body-xs" textAlign={"center"}>
            Cover Art by Rana Fetit
          </Typography>
        </Card>
        <Card sx={{ alignItems: "center", m: 2, width: "30%" }}>
          <img
            loading="lazy"
            src={TheSecondOneArtPiece}
            alt="TheSecondOneArtPiece"
            width={!md ? "70%" : "40%"}
          />
          <Typography
            variant="plain"
            level={!md ? "title-md" : "title-sm"}
            textAlign={"center"}
          >
            BOOK 2
          </Typography>
          <Typography
            variant="plain"
            level={!md ? "title-lg" : "title-md"}
            textAlign={"center"}
          >
            2025
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor:
                mode === "dark"
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.8)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0,
              transition: "opacity 0.3s ease",
              backdropFilter: "blur(10px)",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Typography
                variant="plain"
                level={!md ? "title-md" : "title-sm"}
                m={1}
                textAlign={"center"}
              >
                Coming Early 2025
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Typography variant="plain" level="body-lg">
                With your support, as early as next year!
              </Typography>
              <Typography variant="plain" level="body-sm" textAlign={"justify"}>
                While we already have the manuscript ready, the process of
                editing, marketing, and generating content around it takes time.
                We are both working off cobbled scraps of spare time, but the
                more support we get for this project, the more time we'll be
                able to dedicate to it. Please consider checking out our patreon
                if you're interested in supporting us!
              </Typography>
              <Typography variant="plain" level="body-sm" textAlign={"left"}>
                Thank you dearly for dropping by!
              </Typography>
            </Box>
          </Box>
        </Card>
        <Card sx={{ alignItems: "center", m: 2, width: "30%" }}>
          <img
            loading="lazy"
            src={TheThirdOneArtPiece}
            alt="TheThirdOneArtPiece"
            width={!md ? "70%" : "40%"}
          />
          <Typography
            variant="plain"
            level={!md ? "title-md" : "title-sm"}
            textAlign={"center"}
          >
            BOOK 3
          </Typography>
          <Typography
            variant="plain"
            level={!md ? "title-lg" : "title-md"}
            textAlign={"center"}
          >
            2027
          </Typography>
        </Card>
      </Stack>

      {
        <Card variant="soft" sx={{ alignItems: "center", m: 2 }}>
          <Box sx={{ width: lg ? "60vw" : "80vw" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                loading="lazy"
                src={LysiacElement}
                alt="Lysiac"
                style={{ height: "20vh" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 1,
                }}
              >
                <Typography
                  variant="plain"
                  level="title-sm"
                  m={1}
                  textAlign={"center"}
                >
                  9th of Spring 1st, 499 F.O.
                </Typography>
                <Typography variant="plain" level="body-lg">
                  "His was to settle their greatest of wars."
                </Typography>
              </Box>
              <img
                loading="lazy"
                src={MithraElement}
                alt="Mithra"
                style={{ height: "20vh" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pr: 2,
                pt: 1,
                pl: 2,
                pb: 1,
              }}
            >
              <Typography variant="plain" level="body-sm" textAlign={"justify"}>
                We bid you welcome, to the first volume telling the tale of
                Osgara. Where the young and newly promoted Battlemaster Lysiac
                seeks to prove his worth to his Empire. Where the Sapphire Lady
                of War Mithra waits patiently in her citadel city. Where a young
                village girl stumbles onto a trail of magic. Where scholars and
                scoundrels wrestle with an ominous truth, hidden in texts of
                rambling lunatics. Where some search for destiny, and others are
                drawn to it by fate. Where Silea opens her eyes, washed ashore,
                with no recollection of past nor purpose.
              </Typography>

              <Typography variant="plain" level="body-sm" textAlign={"left"}>
                We bid you welcome, to a world of Myth & Reality.
              </Typography>
            </Box>
            <Box>
              <Tooltip title={"Free Novella"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="solid"
                  component={"a"}
                  href="#reportsOfRebellion"
                >
                  Reports of Rebellion
                </Chip>
              </Tooltip>

              {/* Winter Season */}
              <Tooltip title={"Winter Novella"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="solid"
                  component={"a"}
                  href="https://amzn.eu/d/5JLLdjT"
                  target="_blank"
                >
                  Heart of Gold
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: December"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                  component={"a"}
                  href="https://www.patreon.com/posts/sapphire-tier-94733129"
                  target="_blank"
                >
                  A Quiet Breath
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: January"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                  component={"a"}
                  href="https://www.patreon.com/posts/sapphire-tier-96392293"
                  target="_blank"
                >
                  Sanctimonious Sanctity
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: February"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                  component={"a"}
                  href="https://www.patreon.com/posts/sapphire-tier-98206968?utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=postshare_creator&utm_content=join_link"
                  target="_blank"
                >
                  Clear Solutions
                </Chip>
              </Tooltip>

              {/* Spring Season */}
              <Tooltip title={"Spring Novella"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="solid"
                  component={"a"}
                  href="https://amzn.eu/d/5EPxf4G"
                  target="_blank"
                >
                  Scoundrels!
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: March"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                  component={"a"}
                  href="https://www.patreon.com/posts/sapphire-tier-100469114?utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=postshare_creator&utm_content=join_link"
                  target="_blank"
                >
                  Half Measures
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: April"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Uncelebrated
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: May"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Stubborn Flow
                </Chip>
              </Tooltip>

              {/* Summer Season */}
              <Tooltip title={"Summer Novella"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="solid"
                  component={"a"}
                  href="https://amzn.eu/d/08cUpp8h"
                  target="_blank"
                >
                  Juniara: Last of the Free
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: June"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Quinquennial Chorus
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: July"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Fleeing Moments
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: August"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  In the Red of Night
                </Chip>
              </Tooltip>

              {/* Fall Season */}
              <Tooltip title={"Autumn Novella"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="solid"
                >
                  False Kings
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: September"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Elders of Frost
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: October"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Interdicted Insight
                </Chip>
              </Tooltip>
              <Tooltip title={"Patreon Exclusive Short Story: November"}>
                <Chip
                  sx={{ m: 0.5, textTransform: "uppercase" }}
                  variant="plain"
                >
                  Court Jester
                </Chip>
              </Tooltip>
            </Box>
          </Box>
        </Card>
      }
      <ButtonRedirects />
    </Box>
  );
}
