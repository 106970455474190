import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Card, Chip, Sheet, Stack, Typography } from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";
import ReactPlayer from "react-player/lazy";

export interface RealmData {
  title: string;
  description: string;
  imageComponent: ReactJSXElement;
  capital: string;
  videoUrl: string;
  epithet: string;
  shortVideos?: {
    title: string;
    link: string;
  }[];
}

type Props = {
  realm: RealmData;
};

export default function RealmsBanner(props: Props) {
  const {
    title,
    description,
    imageComponent,
    capital,
    videoUrl,
    epithet,
    shortVideos,
  } = props.realm;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      direction={!md ? "row" : "column"}
      sx={{
        width: "70%",
        mt: theme.spacing(2),
      }}
    >
      {videoUrl === "Soon" ? (
        <Sheet
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            padding: 10,
          }}
        >
          <Typography>Video Coming Soon!</Typography>
        </Sheet>
      ) : (
        <ReactPlayer
          style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}
          url={videoUrl}
          light
        ></ReactPlayer>
      )}
      <Card
        orientation="vertical"
        sx={{
          ml: !md ? 2 : 0,
          maxWidth: !md ? "60%" : "100%",
          mt: !md ? undefined : theme.spacing(2),
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {imageComponent}
          <Typography sx={{ ml: 2 }} level="title-md">
            {title}
          </Typography>
          <Typography sx={{ ml: 2 }} level="body-sm">
            {epithet}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography level="title-sm">Capital City:</Typography>
          <Typography level="body-lg" ml={1}>
            {capital}
          </Typography>
        </Box>
        <Typography level="body-md" textAlign={"justify"}>
          {description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {shortVideos?.map((videoData, index) => (
            <Chip
              key={videoData.title}
              onClick={() => window.open(videoData.link, "_blank")}
              size="lg"
              sx={{ mr: 1, mb: 1 }}
            >
              {videoData.title}
            </Chip>
          ))}
        </Box>
      </Card>
    </Stack>
  );
}
