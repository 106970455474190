import { Book, BookOnline, OpenInNew, PictureAsPdf } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import ReportsElement from "../assets/Reports/Reports Of Rebellion Web.webp";
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, runTransaction } from "firebase/database";

export default function PreludeSection() {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBeLwZpYIPjf7MsHoxNyJuUzAnrZBjWhag",
    authDomain: "timeless-aria.firebaseapp.com",
    databaseURL:
      "https://timeless-aria-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "timeless-aria",
    storageBucket: "timeless-aria.appspot.com",
    messagingSenderId: "83219935199",
    appId: "1:83219935199:web:a77ed5a46df4470cb69e6a",
    measurementId: "G-W1DVYEFLTK",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const database = getDatabase();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const analytics = getAnalytics(app);
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography level="title-lg" component="a" id="reportsOfRebellion">
        REPORTS OF REBELLION
      </Typography>
      <Typography level="body-xs" sx={{ mb: 2 }}>
        Copyright © 2023 Mostafa Elbehery & Aly Said, All rights reserved.
      </Typography>
      <img
        src={ReportsElement}
        width={"550px"}
        alt="Reports of Rebellion Cover"
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", mt: 4 }}>
          <Button
            aria-label="Free on Patreon"
            sx={{ ml: 1 }}
            component="a"
            href="https://www.patreon.com/posts/we-bid-thee-93373288"
            target="_blank"
            startDecorator={<OpenInNew />}
          >
            Free on Patreon
          </Button>
          <Button
            aria-label="Download PDF"
            sx={{ ml: 1 }}
            component="a"
            href="/assets/Timeless' Aria - Reports of Rebellion.pdf"
            download
            onClick={() => {
              runTransaction(ref(database, "ror-pdf"), (currentValue) => {
                return (currentValue || 0) + 1;
              });
            }}
            startDecorator={<PictureAsPdf />}
          >
            Download PDF
          </Button>
          <Button
            aria-label="Download EPUB"
            sx={{ ml: 1 }}
            component="a"
            href="/assets/Timeless' Aria - Reports of Rebellion.epub"
            download
            onClick={() => {
              runTransaction(ref(database, "ror-epub"), (currentValue) => {
                return (currentValue || 0) + 1;
              });
            }}
            startDecorator={<BookOnline />}
          >
            Download EPUB
          </Button>
        </Box>
        <Button
          aria-label="Paperback Version on Amazon"
          sx={{ ml: 1, mt: 1 }}
          component="a"
          href="https://www.amazon.co.uk/gp/product/3911111010"
          startDecorator={<Book />}
        >
          Paperback Version on Amazon
        </Button>
      </Box>
    </Box>
  );
}
