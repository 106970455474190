import { Book, Favorite, OpenInNew, YouTube } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";

export default function ButtonRedirects() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex" }}>
        {!md ? (
          <Button
            aria-label="Support us on Patreon"
            sx={{ ml: 1 }}
            component="a"
            href="https://www.patreon.com/timelessaria"
            color="danger"
            startDecorator={<Favorite />}
            target="_blank"
          >
            Support us on Patreon
          </Button>
        ) : (
          <Tooltip title="Support us on Patreon">
            <IconButton
              aria-label="Support us on Patreon"
              sx={{ ml: 1 }}
              component="a"
              href="https://www.patreon.com/timelessaria"
              color="danger"
              variant="solid"
              target="_blank"
            >
              <Favorite />
            </IconButton>
          </Tooltip>
        )}
        {!md ? (
          <Button
            sx={{ ml: 1 }}
            aria-label="Check us out on YouTube"
            component="a"
            href="https://www.youtube.com/@TimelessAriaFantasy"
            target="_blank"
            color="neutral"
            startDecorator={<YouTube />}
          >
            Check us out on YouTube
          </Button>
        ) : (
          <Tooltip title="Check us out on YouTube">
            <IconButton
              aria-label="Check us out on YouTube"
              sx={{ ml: 1 }}
              component="a"
              href="https://www.youtube.com/@TimelessAriaFantasy"
              color="neutral"
              variant="solid"
              target="_blank"
            >
              <YouTube />
            </IconButton>
          </Tooltip>
        )}

        {!md ? (
          <Tooltip title="Check your local Amazon Marketplace!">
            <Button
              sx={{ ml: 1 }}
              aria-label="Available on Amazon"
              component="a"
              href="https://www.amazon.co.uk/-/en/gp/product/B0CNP3RNC5"
              color="warning"
              startDecorator={<OpenInNew />}
            >
              Available on Amazon
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Available on Amazon">
            <IconButton
              aria-label="Available on Amazon"
              sx={{ ml: 1 }}
              component="a"
              href="https://www.amazon.co.uk/-/en/gp/product/B0CNP3RNC5"
              color="warning"
              variant="solid"
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {!md ? (
        <Button
          sx={{ ml: 1, mt: 1 }}
          aria-label="Read the Free Prelude"
          component="a"
          href="#reportsOfRebellion"
          startDecorator={<Book />}
        >
          Read the Prelude for Free!
        </Button>
      ) : (
        <Tooltip title="Read the Prelude for Free!">
          <IconButton
            aria-label="Read the Prelude for Free!"
            sx={{ ml: 1, mt: 1 }}
            component="a"
            href="#as-link"
            variant="solid"
            color="primary"
          >
            <Book />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
