import { Brightness2, Brightness5 } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import { useColorScheme } from "@mui/joy/styles";

export default function ModeToggle() {
  const { mode, setMode } = useColorScheme();
  return (
    <Button
      aria-label="Dark Mode"
      variant="outlined"
      color="neutral"
      onClick={() => setMode(mode === "dark" ? "light" : "dark")}
      size="sm"
      sx={{ position: "fixed", right: "15px", top: "15px", zIndex: "100000" }}
    >
      {mode === "dark" ? <Brightness5 /> : <Brightness2 />}
    </Button>
  );
}
