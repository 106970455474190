import { Typography } from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";
import HydariaElement from "../assets/Realms/Hydaria.webp";
import MigoraElement from "../assets/Realms/Migora.webp";
import MotraElement from "../assets/Realms/Motra.webp";
import RigataElement from "../assets/Realms/Rigata.webp";
import VeraElement from "../assets/Realms/Vera.webp";
import { RealmData } from "./RealmsBanner";
import RealmsBanners from "./RealmsBanners";

const SapphireShoreRealmsData: RealmData[] = [
  {
    title: "The Empire of Hydaria",
    description:
      "For over a thousand years the Empire of Hydaria has endured. Through a tumultuous period of foundation, in which it rallied around one called Hida. To embracing her teachings, and emerging out of many conflicts that are now lost to the pages of history. Today Hydaria is the mightiest of all five great realms, it boasts Osgara's most organised military, and it lays claim over being Osgara's most cultured realm. Claiming the epithet of: 'The Pinnacle of Civilisation'. The Hydarians show this through their tradition of monthly festivals, each celebrating a different aspect of their lives. Hydaria is not, however, the largest of the five great realms. Its official borders extend from the Silent River to the east, the sea to the west, and to uncharted lands in the north. But its southern border ends at the Foreboding forest and with the Opulent Desert. However, Hydaria's influence extends to the farthest reaches of the south, as it has completely subjugated the Realm of Rigata.",
    imageComponent: (
      <img loading="lazy" width={"5%"} src={HydariaElement} alt="Hydaria" />
    ),
    videoUrl: "https://www.youtube.com/watch?v=umult8wqA2w",
    capital: "Orvabad",
    epithet: "The Pinnacle of Civilisation",
    shortVideos: [
      {
        title: "Hydarian Calendar",
        link: "https://youtube.com/shorts/YnibMnBtAZc?feature=share",
      },
      {
        title: "Hydarian Festivals",
        link: "https://youtube.com/shorts/uXzbTDq6qoM?feature=share",
      },
      {
        title: "The Battle of Tinerus River",
        link: "https://youtu.be/R93-kF6z_TQ",
      },
      {
        title: "The Battle of Arleth Stead",
        link: "https://youtube.com/shorts/KYGpPSlcam8?feature=share",
      },
      {
        title: "Naming Convention: Hydaria",
        link: "https://youtube.com/shorts/8jv-qMRBc-4?feature=share",
      },
    ],
  },
  {
    title: "The Kingdom of Migora",
    description:
      "Formerly known as The Ralmera of Migora, the massive kingdom that stands today is the fruit of High King Alon's labours. From twelve great Ralms, and a myriad of smaller ones, Alon has led an arduous unification process long in the making. Migora was not always fractured, but it has never remained united for long either. In fact, the very first Migorans were Fringe Hydarians that suffered from oppression under Pure Blooded Hydarians at the time. They rallied around one called Miga, who led them into a mass exodus east, to pristine lands they could call their own. They settled these lands, built cities, towns, villages, and farms. Then, valuing freedom above all else, they fractured into groups called Ralms. Many attempts at reunification would fail before Alon's, but today Migora stands as largest of the five realms, its borders starting from the river, and nearly encompassing the entirety of Osgara's east. It has earned its place in Osgara, and it gives back in the from of its staple Golden Weave.",
    imageComponent: (
      <img loading="lazy" width={"5%"} src={MigoraElement} alt="Migora" />
    ),
    videoUrl: "https://youtu.be/nNqhZzh41F4",
    capital: "Tunhil",
    epithet: "Ralms of the Free",
    shortVideos: [
      {
        title: "Ralms & Kings",
        link: "https://youtube.com/shorts/O17cAv-H_so?feature=share",
      },
      {
        title: "Migoran Provinces: The Mark",
        link: "https://youtube.com/shorts/Q7JBxmgqCL0?feature=share",
      },
      {
        title: "Migoran Provinces: The Heartlands",
        link: "https://youtube.com/shorts/37zF-HnkomI?feature=share",
      },
      {
        title: "High King Alon",
        link: "https://youtube.com/shorts/LJXaOvK8xm0?feature=share",
      },
      {
        title: "Cities of Osgara: Tunhil",
        link: "https://youtube.com/shorts/5wdHpfO7FFg?feature=share",
      },
      {
        title: "Cities of Osgara: Mithra City",
        link: "https://youtube.com/shorts/ISFssjKpMAo?feature=share",
      },
      {
        title: "Lords of War: Migoran Armies",
        link: "https://youtube.com/shorts/PiKF87mvyiQ?feature=share",
      },
      {
        title: "The Sapphire Guard",
        link: "https://youtube.com/shorts/uT-Vhy8iqxY?feature=share",
      },
      {
        title: "Sapphire Lords: Eleia",
        link: "https://youtube.com/shorts/P61zfN5WZXc?feature=share",
      },
      {
        title: "Naming Convention: Migora",
        link: "https://youtube.com/shorts/IM1bTmRKoCI?feature=share",
      },
      {
        title: "Road Networks",
        link: "https://youtube.com/shorts/BA1tRDAfORU?feature=share",
      },
      {
        title: "Produce: Golden Weave",
        link: "https://youtube.com/shorts/u_R6ulh0yZA?feature=share",
      },
      {
        title: "Places in Migora: Twelve Petals",
        link: "https://youtube.com/shorts/zvpH_vqs9J4?feature=share",
      },
    ],
  },
  {
    title: "The Subjugated Realm of Rigata",
    description:
      "One of the oldest standing Kingdoms of Osgara, reduced to a subservient entity to Hydaria. Rigata has ever been a realm of the people, their nobility has always valued their subjects and so their subjects adore them back. It is by far the most populous of the five realms, and most fertile in land as well. In its zenith, Rigata boasted builders and architects that would set about creating wonders. In every corner of Rigata one can see vestiges of that time long passed, now they fight for their freedom. The web of Hydarian spies is well entrenched in Rigata, and yet they manage to rebel time and time again. Hoping that one day they can reclaim their sovereignty and trade their new tyrannical masters, for the nobility they would have died to protect.",
    imageComponent: (
      <img loading="lazy" width={"5%"} src={RigataElement} alt="Rigata" />
    ),
    videoUrl: "https://youtu.be/3AkML0j4-14",
    capital: "Rigata",
    epithet: "Rigata Green",
    shortVideos: [
      {
        title: "Rigata Green",
        link: "Soon",
      },
      {
        title: "Naming Convention: Rigata & Vera",
        link: "Soon",
      },
    ],
  },
  {
    title: "The Stone Sovereignty of Motra",
    description:
      "Ever since the dawn of time there has been a Stone Sovereign presiding over Motra beneath the mountain. Stoutest and hardiest among all peoples of Osgara, the Motrans have been blessed by the Mountain and they have blessed it back with a prosperous realm. They take great pride in their craft, whatever it may be, so much so that their last names change as they change professions. They are loyal beyond the shadow of a doubt, they are a just people, but they are also often cold and firm. Though even as stable as the mountain realm is, their latest Stone Sovereign Miry seeks to make reforms of her own. With the rapid expansion of Migora, and the subjugation of Rigata, she fears that Motra would be lost to the wheels of progress should they not reform. Her hand has been heavier than Alon with her reformations as well, taking the ancient mountain realm, into systems of nobility and governance systems that line up better with their peers.",
    imageComponent: (
      <img loading="lazy" width={"5%"} src={MotraElement} alt="Motra" />
    ),
    videoUrl: "https://youtu.be/DwJRYvJyBCA",
    capital: "Motra",
    epithet: "The Mountain Realm",
    shortVideos: [
      {
        title: "Stone Sovereigns",
        link: "Soon",
      },
      {
        title: "Naming Convention: Motra",
        link: "Soon",
      },
    ],
  },
  {
    title: "The Archony of Vera",
    description:
      "In the far south, shielded by mountainous regions, and veiled by marshlands and rivers, lies the Archony of Vera. A collection of city states linked by bloodlines and ancestral bonds of kinship. They are a people that value thought above all else, in whatever shape it takes, though they are not shy to defend themselves if need be. They host some of Osgara's deepest thinkers, though they are in a way secluded from the rest of the world, claiming no political ties to Migora or Motra. Even their hostility to the Rigatans is stifled by terrain, and one Rigatan Landmaster that has proven to be an organised and capable defender. ",
    imageComponent: (
      <img loading="lazy" width={"5%"} src={VeraElement} alt="Vera" />
    ),
    videoUrl: "https://youtu.be/Gm-RMysWHyI",
    capital: "/",
    epithet: "Strength, Wisdom, Intellect",
  },
];

export default function SapphireShoreRealms() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Typography
        textAlign={"center"}
        sx={{ mb: 4 }}
        level={!md ? "title-lg" : "title-sm"}
      >
        The Five Great Realms of Osgara
      </Typography>
      <RealmsBanners realms={SapphireShoreRealmsData} />{" "}
    </>
  );
}
