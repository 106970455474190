import { Grid } from "@mui/joy";
import RealmsBanner, { RealmData } from "./RealmsBanner";

type Props = {
  realms: RealmData[];
};

export default function RealmsBanners(props: Props) {
  const { realms } = props;
  return (
    <Grid
      container
      spacing={2}
      sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}
    >
      {realms.map((realm) => (
        <RealmsBanner realm={realm} key={realm.title} />
      ))}
    </Grid>
  );
}
