import { Box, Typography } from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";
import L from "leaflet";
import "leaflet-rastercoords";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef } from "react";
import MapLegend from "../assets/MapLegend.png";

interface MapPoint {
  x: number;
  y: number;
  id: string;
  name: string;
}

interface InteractiveMapProps {
  points?: MapPoint[];
}
const InteractiveMap: React.FC<InteractiveMapProps> = ({ points }) => {
  const mapRef = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (mapRef.current?.dataset.uninitialized) {
      delete mapRef.current.dataset.uninitialized;
      const map = L.map(mapRef.current, {
        crs: L.CRS.Simple,
        minZoom: 2,
        maxZoom: 7,
      });
      const img = { width: 9728, height: 9728 };
      const rc = new L.RasterCoords(map, [img.width, img.height]);
      map.setView(rc.unproject([img.width / 2 + 200, img.height / 2 - 400]), 2);
      map.attributionControl.setPrefix("");
      L.tileLayer("/map/tiles/{z}/{x}/{y}.png", {
        noWrap: true,
        attribution: "",
        bounds: rc.getMaxBounds(),
        maxNativeZoom: rc.zoomLevel(),
      }).addTo(map);

      // // adding layers
      // const layerPolygon = L.polygon(
      //   (
      //     [
      //       [4120, 3870], // Yokuk
      //       [4860, 4000], // Yergamond
      //       [4850, 4500], // Grahir
      //     ] as [number, number][]
      //   ).map((p) => rc.unproject(p))
      // );
      // map.addLayer(layerPolygon);
      // L.control
      //   .layers(
      //     {},
      //     {
      //       Polygon: layerPolygon,
      //     }
      //   )
      //   .addTo(map);
    }
  }, []);
  return (
    <Box
      sx={{
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": { display: "none" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography textAlign={"center"} sx={{ mb: 2 }} level="title-lg">
        Map of Osgara, 499 F.O.
      </Typography>
      <Typography textAlign={"center"} level="body-sm">
        Thank you Antonio Purrmann!
      </Typography>
      <Typography textAlign={"center"} sx={{ mb: 2 }} level="body-sm">
        For demystifying how to get this map working in this tech stack! ❤️
      </Typography>
      {!md && (
        <Box sx={{ position: "relative", width: "90%" }}>
          <img
            loading="lazy"
            src={MapLegend}
            style={{
              width: "250px",
              position: "absolute",
              right: "1%",
              zIndex: 1000, //Magic Number
            }}
            alt="Map Legend"
          />
        </Box>
      )}
      <div
        ref={mapRef}
        style={{ width: "90%", height: 700, background: "#DDCFC3" }}
        data-uninitialized
      ></div>
    </Box>
  );
};

export default InteractiveMap;
