import { Box, Divider, ThemeProvider, Typography } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import CookieConsent from "react-cookie-consent";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import InteractiveMap from "./components/InteractiveMap";
import ModeToggle from "./components/ModeToggle";
import PreludeSection from "./components/PreludeSection";
import SapphireShoreRealms from "./components/SapphireShoreRealms";
import StainedHeader from "./components/StainedHeader";
import theme from "./theme";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Sheet
        variant="plain"
        color="neutral"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <ModeToggle />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <StainedHeader />
          <Divider sx={{ m: 4 }} />
          <InteractiveMap />
          <Divider sx={{ m: 4 }} />
          <SapphireShoreRealms />
          <Divider sx={{ m: 4 }} />
          <PreludeSection />
          <Divider sx={{ m: 4 }} />
          <AboutUs />
          <CookieConsent
            enableDeclineButton
            acceptOnScrollPercentage={5}
            onAccept={(acceptedByScrolling) => {
              if (acceptedByScrolling) {
                // Accepted by scrolling
              } else {
                // Accepted by clicking
              }
            }}
          >
            <Typography>
              This website uses cookies to enhance the user experience.
            </Typography>
          </CookieConsent>
        </Box>
      </Sheet>
      <Footer />
    </ThemeProvider>
  );
}
