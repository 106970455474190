import { extendTheme } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
  // No custom tokens found, you can skip the theme augmentation.
}

const theme = extendTheme({
  typography: {
    h1: {
      fontFamily: "'Cinzel', serif",
      fontSize: "126px",
      fontWeight: "100",
    },
    h2: {
      fontFamily: "'Cinzel', serif",
      fontSize: "72px",
      fontWeight: "100",
    },
    h3: {
      fontFamily: "'Cinzel', serif",
      fontSize: "48px",
      fontWeight: "100",
    },
    h4: {
      fontFamily: "'Cinzel', serif",
      fontSize: "36px",
      fontWeight: "100",
    },
    "title-lg": {
      fontFamily: "'Cinzel', serif",
      fontSize: "30px",
      fontWeight: "100",
    },
    "title-md": {
      fontFamily: "'Cinzel', serif",
      fontSize: "24px",
      fontWeight: "100",
    },
    "title-sm": {
      fontFamily: "'Cinzel', serif",
      fontSize: "18px",
      fontWeight: "100",
    },
    "body-lg": {
      fontFamily: "'EB Garamond', serif",
      // other CSS rules
    },
    "body-md": {
      fontFamily: "'EB Garamond', serif",
      // other CSS rules
    },
    "body-sm": {
      fontFamily: "'EB Garamond', serif",
      // other CSS rules
    },
    "body-xs": {
      fontFamily: "'EB Garamond', serif",
      // other CSS rules
    },
  },
});

export default theme;
