import { Box, Typography } from "@mui/joy";

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        m: 1,
      }}
    >
      <Typography level="body-xs">
        © Mostafa Elbehery & Aly Said 2023 - {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
