import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  FormControl,
  Radio,
  RadioGroup,
  Sheet,
  Typography,
} from "@mui/joy";
import { Fragment } from "react";
import ReactPlayer from "react-player";

type Props = {
  week: WeekData;
};

export interface WeekData {
  question: QuestionData;
  answer?: AnswerData;
}

export interface QuestionData {
  weekTitle: string;
  paragraphs: string[];
  choices: ChoiceData[];
  selectedChoiceId?: string;
}

export interface ChoiceData {
  id: string;
  value: string;
}

export interface AnswerData {
  answerTitle: string;
  videoAnswers: {
    paragraphs: string[];
    link: string;
  }[];
}

export default function SapphireSaturdaysWeek(props: Props) {
  return (
    <Sheet>
      <Accordion>
        <AccordionSummary>{props.week.question.weekTitle}</AccordionSummary>
        <AccordionDetails>
          <Card variant="soft" sx={{ alignItems: "center", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pr: 2,
                pt: 1,
                pl: 2,
                pb: 1,
              }}
            >
              <Typography variant="plain" level="body-lg" textAlign={"justify"}>
                {props.week.question.weekTitle}
              </Typography>

              {props.week.question.paragraphs.map((paragraph) => (
                <Typography
                  key={paragraph}
                  variant="plain"
                  level="body-sm"
                  textAlign={"justify"}
                >
                  {paragraph}
                </Typography>
              ))}
            </Box>
            <FormControl>
              <RadioGroup
                defaultValue={props.week.question.selectedChoiceId}
                name="radio-buttons-group"
              >
                {props.week.question.choices.map((choice) => (
                  <Radio
                    key={choice.id}
                    value={choice.id}
                    label={choice.value}
                    disabled
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Card>

          {props.week.answer && (
            <Card variant="outlined" sx={{ alignItems: "center", m: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  pr: 2,
                  pt: 1,
                  pl: 2,
                  pb: 1,
                }}
              >
                <Typography
                  variant="plain"
                  level="body-lg"
                  textAlign={"justify"}
                >
                  {props.week.answer.answerTitle}
                </Typography>
                {props.week.answer.videoAnswers.map((answer) => (
                  <Fragment key={answer.link}>
                    {answer.paragraphs.map((paragraph) => (
                      <Typography
                        variant="plain"
                        level="body-sm"
                        textAlign={"justify"}
                        key={paragraph}
                      >
                        {paragraph}
                      </Typography>
                    ))}

                    <ReactPlayer
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "center",
                        marginBottom: "16px",
                        marginTop: "16px",
                      }}
                      url={answer.link}
                      controls
                      width={"56.25%"}
                    ></ReactPlayer>
                  </Fragment>
                ))}
              </Box>
            </Card>
          )}
          <Divider />
        </AccordionDetails>
      </Accordion>
    </Sheet>
  );
}
