import { Favorite, YouTube } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Typography } from "@mui/joy";
import { useState } from "react";
import HydariaElement from "../assets/Realms/Hydaria.webp";
import MigoraElement from "../assets/Realms/Migora.webp";

export type AboutText = "default" | "Aly" | "Mostafa";

export default function AboutUs() {
  const [textVersion, setTextVersion] = useState<AboutText>("default");
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography level="title-lg">ABOUT THE AUTHORS</Typography>
      {textVersion !== "default" && (
        <Button
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={() => setTextVersion("default")}
          aria-label="About Us Short Summary"
        >
          Short Version
        </Button>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 2,
          }}
        >
          <img
            src={MigoraElement}
            alt="Mostafa Elbehery"
            loading="lazy"
            onClick={() => setTextVersion("Mostafa")}
            style={{ cursor: "pointer" }}
            aria-label="About Us Mostafa"
          />
          <Typography sx={{ m: 1 }} level="title-md">
            MOSTAFA A.M. ELBEHERY
          </Typography>
          <Typography
            sx={{ m: 1 }}
            level="body-md"
            component="a"
            href="mailto:mostafa@timelessaria.com"
          >
            mostafa@timelessaria.com
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 2,
          }}
        >
          <img
            src={HydariaElement}
            alt="Aly Said"
            loading="lazy"
            onClick={() => setTextVersion("Aly")}
            style={{ cursor: "pointer" }}
            aria-label="About Us Aly"
          />
          <Typography sx={{ m: 1 }} level="title-md">
            ALY M.A. SAID
          </Typography>
          <Typography
            sx={{ m: 1 }}
            level="body-md"
            component="a"
            href="mailto:aly@timelessaria.com"
          >
            aly@timelessaria.com
          </Typography>
        </Box>
      </Box>
      {textVersion === "default" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mr: 5,
            ml: 5,
            mt: 2,
            mb: 2,
            maxWidth: "900px",
            alignItems: "center",
          }}
        >
          <Typography m={1} level="body-lg">
            Lifelong friends with a passion for storytelling.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Based out of Germany and Scotland, Mostafa Elbehery & Aly Said have
            been working together on building a massive fantasy world as a
            platform for telling tales of political intrigue, magical
            adventures, and fantastical epics. Inspired by the transformative
            capabilities such stories hold, offering exciting flights of fancy
            into fictional worlds that are conjurable only in one's mind.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            While they have attainted Master's Degrees in Computer Science and
            Media Technology, and have written award winning academic papers,
            they have always been enthralled by the world of fantasy. Inspired
            by titans of the genre such as J.R.R Tolkien, George R.R Martin,
            David & Leigh Eddings, amongst numerous others, they have set about
            creating the world of Osgara. For which a first series "Timeless'
            Aria" is being published, the first volume "The Sapphire Shore"
            having been released in November of 2023, and the next two volumes
            completing the trilogy are already written and need only to be
            edited before they can be shared with the world.
          </Typography>
          <Typography component="div" level="body-md" textAlign={"justify"}>
            Going beyond the main series itself, they plan to release Novellas
            on a seasonal basis, the first of which acts as a prelude to the
            main novels and can be found freely above. Going forward, these
            Seasonal Novellas will be published as well, and are offered to
            their patreons at:{" "}
            <Chip
              size="sm"
              startDecorator={<Favorite />}
              variant="outlined"
              onClick={() =>
                window.open("https://www.patreon.com/timelessaria", "_blank")
              }
            >
              /timelessaria
            </Chip>
            , as well as exclusive Short Stories on a monthly basis. They've
            also hand crafted a few videos to share details and snippets about
            the world, available on{" "}
            <Chip
              size="sm"
              startDecorator={<YouTube />}
              variant="outlined"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/@TimelessAriaFantasy",
                  "_blank"
                )
              }
            >
              @TimelessAriaFantasy
            </Chip>
            .
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            With enough support, they hope to turn these compelling hobbies and
            passion projects, into a serious full time craft, an adventure to be
            sure, and one that you too can join along.
          </Typography>
        </Box>
      )}
      {textVersion === "Aly" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mr: 5,
            ml: 5,
            mt: 2,
            mb: 2,
            maxWidth: "900px",
            alignItems: "center",
          }}
        >
          <Typography m={1} level="body-lg">
            I’ve always loved storytelling and I’ve always been a harsh critic,
            hard to please.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Aly here, my story is a little different, perhaps a bit more
            arrogant though I do nothing out of arrogance. I’ve read, seen,
            heard and sat through many stories, and I’ve always found ways to
            poke holes at them. When it came to reading Mostafa’s writings, I
            poked constructively, and when it came time for me to write, I
            realised my arrogance.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Writing is a wonderful outlet of a variety of emotions, but writing
            one’s heart out is no guarantee for a good story. When it came time
            for me to write, I struggled with it greatly, not in forming a
            coherent plot or linguistically sound writing. I struggled with
            painting the amazing worlds I saw in my minds eye with my words, but
            I loved stories and I could not stop. So I looked for help. If, in
            the course of reading our books, you’ve not noticed two distinct
            writing styles. That will be success for my imitation skills, for
            when it came time to ask for help and improve my writing, I went to
            my best friend.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            We’ve studied together, we’ve been on countless nonsensical
            adventures and we’ve worked together in a start-up of our own. The
            ride’s always been fun, and writing a book together is hopefully one
            of the many things we’ll keep doing together through our journey in
            life.
          </Typography>
        </Box>
      )}
      {textVersion === "Mostafa" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mr: 5,
            ml: 5,
            mt: 2,
            mb: 2,
            maxWidth: "900px",
            alignItems: "center",
          }}
        >
          <Typography m={1} level="body-lg">
            "You did what now?" - Mostafa Elbehery 2008
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Mostafa here, I wanted to tell this story from my POV just because
            we thought it was funnier with context.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Everyone is undoubtedly exceptional in some way, you only have to
            find it. For me, I thought I had found that in the form of top
            rankings and exceptional grades at school at a very young age. But
            everything changed when the fire nation attacked. One new addition
            to our class, who is now one of my dearest friends, ousted me
            handily. No amount of studying would see me return to former glory,
            and yet on one random day we were both asked to participate in a
            National Short Story Competition. Then, one short pirate-themed tale
            with a joke treasure later, and the tables were turned. We swapped
            fortunes in this one field, and as if I did not already enjoy
            creative writing, that spark lit a fire that burns to this day.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Fast forward to IGCSE level education and things changed. You see,
            up until that point our writing tasks were loose enough to allow for
            creativity, but no longer. IGCSE exams were rigid, and rightly so
            for what they wished to test for. I, however, had 'no time for that'
            and so inspired by the single most epic piece of writing I had laid
            eyes upon at the time, I took it upon myself to create a full
            fantasy world. (Thank you, Professor Tolkien.) This is 2006 roughly,
            and youthful exuberance turned out to be one force of nature that I
            cannot comprehend. I wrote four books in the span of two to three
            years, and the process was magical. Of course, I made so many
            obvious mistakes I was oblivious to at the time, but it was magical
            nontheless. Though I had a problem, I was writing these massive
            books that no one had the time to read. I was writing to an audience
            of none. (Bless you mom for finding the time to read the first one!)
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Enter, Aly.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            We met during these IGCSE years and quickly became best of friends.
            We did a whole slew of crazy things that would pass for content
            creation these days I'm almost sure of it. We rewrote song lyrics,
            we recorded skits, we got a green blanket and pretended it was a
            green screen and went filming. We watched Lord of the Rings together
            and attempted to recrate the charge of the Rohirrim in my back yard.
            Then I sort of passed him the first book I wrote, and at this point
            I had given it to multiple people who VERY understandably did not
            have the time for 600 A4 pages of a 16-Year Old's rambling. So I
            thought at best he'd give me some feedback, at worst he'd forget
            about it.
          </Typography>
          <Typography m={1} level="body-lg">
            "I read it. Yeah, all of it." - Aly Said 2008
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            In one day he read what I wrote in months, he had feedback, he
            spotted mistakes, and above all else, he was excited to read the
            rest of it. Book after book he devoured them whole, we discussed
            them, we dissected them, and we enjoyed them, I now had an audience
            of one. However, at this point we were gearing up to head into five
            long years of Engineering Degrees, so nothing serious came of it at
            the time.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            Fast forward one last time and our lives looked far more detached
            from what we would have imagined them. We were both doing Masters in
            different countries, and we were adjusting to new norms. Each being
            in their own world gave us far less time to talk, and it was only
            ever going to be online. So it was a pleasant surprise when Aly gave
            me a ten chapter introduction to something he had been creating in
            our time apart, this came at the very same time I was following
            lectures by one Brandon Sanderson. The devastating advise of "Start
            Over" couldn't have come at a better time.
          </Typography>
          <Typography level="body-md" textAlign={"justify"}>
            It was when we finally reunited back in 2017 that I proposed this
            idea, I vividly remember that fateful car ride too. I mentioned my
            intention of starting anew, just to see how it would look like to
            publish a book. Aly was writing his thing at the time too, and we
            really needed to reconnect over anything, him being in Scotland and
            me in Germany. So, I floated the idea of using our Computer Science
            knowledge to write a fully dynamic book. As you can tell from this
            page, this is not what we ended up doing. But the story from that
            project is what we chiselled away at to get to Timeless' Aria. And
            for such an amazing journey as it has been, I truly hope that these
            are just our first steps, that this is just the beginning. We wrote
            all three books together, we edited them together, and we fussed
            over every little detail together. All in what little scraps of time
            we are able to scrounge from busy working lives.
          </Typography>
          <Typography level="body-lg" textAlign={"justify"}>
            One of my English Teachers once told me to reach for the stars. I
            wonder now what our outstretched hands might find?
          </Typography>
        </Box>
      )}
    </Box>
  );
}
